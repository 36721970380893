import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ImagesliderComponent } from './components/imageslider/imageslider.component';
import { SmImageCropperComponent } from './components/sm-image-cropper/sm-image-cropper.component';
import {ImageToDataUrlModule} from "ngx-image2dataurl";



@NgModule({
    declarations: [ImagesliderComponent, SmImageCropperComponent],
    exports: [
        ImagesliderComponent,
    ],
    imports: [
        CommonModule,
        ImageToDataUrlModule,
    ]
})
export class ImageHandlerModule { }
