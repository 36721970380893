import {Injectable} from "@angular/core";
import {BehaviorSubject, Observable, Subject} from "rxjs";
import {mobiscroll} from "@mobiscroll/angular";

@Injectable({
    providedIn: 'root'
})
export class NotificationsService {
    private confirmationConfig = new BehaviorSubject<any>({});
    private confirmationStatus = new Subject<boolean>();
    constructor() {}
    confirm(message: string): Observable<boolean> {
      this.confirmWithCallback(message, (value) => {
        this.confirmationStatus.next(value);
      });
     return this.confirmationStatus;
    }
    confirmWithCallback(message: string, callBackFn: (value: boolean) => void) {
        let type = 'confirm';
        this.setConfirmation(message, type, callBackFn);
    }
    notification(message: string) {
        const type = 'notification';
        this.setConfirmation(message, type, () => {});
    }
    setConfirmation(message: string,type: string, callBackFn: (value: boolean) => void) {
        let that = this;
        this.confirmationConfig.next({
            type: type,
            message: message,
            callBack: function (value) {
                that.confirmationConfig.next({});
                callBackFn(value);
            }
        })
    }
    getMessage(): Observable<any> {
        return this.confirmationConfig.asObservable();
    }
    // *** Show Notifications ***
    // @params [notificationMessage] => Message to be displayed
    static notify(notificationMessage: string, type: string, position: string) {
        mobiscroll.snackbar({
            message: notificationMessage,
            color: type,
            display: position,
            duration: 7000,
            theme: 'ios'
        }).then();
    }
}
