import { Component, OnInit } from '@angular/core';
import {AppEventsService} from "../../../core/services/appevents.service";

@Component({
  selector: 'offer-loading',
  templateUrl: './loading.component.html',
  styleUrls: ['./loading.component.scss']
})
export class LoadingComponent implements OnInit {

  showLoader: boolean = false;

  constructor(private _appEvents: AppEventsService) {
  }

  ngOnInit() {
    this._appEvents.$showLoader
        .subscribe(
            showLoader => this.showLoader = showLoader
        )
    ;
  }

}
