import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {ListItem} from "../../interface";

@Component({
  selector: 'offer-itemlist',
  templateUrl: './itemlist.component.html',
  styleUrls: ['./itemlist.component.scss']
})
export class ItemlistComponent implements OnInit, OnChanges {

  @Input() itemList: ListItem[];
  @Input() url: string;
  @Input() template: string;

  constructor() {

  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.itemList) {
      this.itemList = changes.itemList.currentValue;
    }
    if (changes.url) {
      this.url = changes.url.currentValue;
    }
  }

  ngOnInit() {
  }
}
