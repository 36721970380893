import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Router} from '@angular/router';
import {UserActionsService} from "../../../core/services/userActions.service";
import {NotificationsService} from "../../../core/services";


@Component({
  selector: 'app-pageheader',
  templateUrl: './pageheader.component.html',
  styleUrls: ['./pageheader.component.scss']
})
export class PageheaderComponent implements OnInit {
  @Input() pageTitle: string;
  @Input() noDispBackBtn: boolean;
  @Input() hasLogOut: boolean;
  @Input() routeTo: string;
  @Input() step: number;
  @Input() isManual: boolean;
  @Input() editMode: boolean;
  @Output() closeComponent: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() stepEvent: EventEmitter<number> = new EventEmitter<number>();

  constructor(private _router: Router,
              private _userService: UserActionsService,
              private _confirmService: NotificationsService) { }


  ngOnInit() {
  }

  back() {
    if (this.isManual) {
      this.closeComponent.emit(true);
      return;
    }
    if (!this.step) {
      this._router.navigate(['/home']);
    }
    if (!this.editMode) {
      if (this.step === 1) {
        this._router.navigate([this.routeTo]);
        return;
      }
      if (this.step === 2) {
        this.step--;
        this.stepEvent.emit(this.step);
        return;
      }
    }
    if (this.editMode) {
      if (this.step === 1) {
        this._router.navigate([this.routeTo]);
        return;
      }
      if (this.step === 2) {
        this._router.navigate([this.routeTo]);
      }
    }
  }
  logOut() {
    const logoutTxt = 'Du er nu ved at logge ud af QuickDeal appen.<br><br> Hvis du fortsætter, skal du logge ind igen for at bruge appen.<br><br><br> Ønsker du at logge ud?'
    this._confirmService.confirmWithCallback(logoutTxt , (accept) => {
      if (accept) {
        this._userService.logout();
      }
    });
  }

}
