import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import {AuthGuard, UserGuard} from "./core/guards";

import {AppComponent} from "./app.component";
import {HomeComponent} from "@shared/components/home/home.component";



const routes: Routes = [
  {
    path: '',
    component: AppComponent,
    canActivate: [AuthGuard, UserGuard]
  },
  {
    path: 'login',
    loadChildren: () => import('./authentication/authentication.module').then(m => m.AuthenticationModule)
  },
  {
    path: 'home',
    component: HomeComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'annonce',
    loadChildren: () => import('./annonce/annonce.module').then(m => m.AnnonceModule)
  },
  {
    path: '**',
    redirectTo: '/home'
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
