import {Injectable} from '@angular/core';
import {fromEvent, Observable} from 'rxjs';
import {debounceTime, map} from 'rxjs/operators';

interface WindowSize {
  width: number;
  height: number;
}

function _window(): any {
  return window;
}

@Injectable({
  providedIn: 'root'
})
export class WindowService {

  constructor() {
  }

  get window(): Window {
    return _window();
  }

  onResize(): Observable<WindowSize> {
    return fromEvent(this.window, 'resize')
      .pipe(
        map(() => {
          return {
            width: this.window.document.documentElement.clientWidth,
            height: this.window.document.documentElement.clientHeight
          };
        }),
        debounceTime(100)
      );
  }
}
