import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PageheaderComponent } from './components/pageheader/pageheader.component';
import { ItemlistComponent } from './components/itemlist/itemlist.component';
import {ImageHandlerModule} from "../image-handler/image-handler.module";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {RouterModule} from "@angular/router";
import {MbscModule} from "@mobiscroll/angular";
import {HomeComponent} from "./components/home/home.component";
import { LoadingComponent } from './components/loading/loading.component';



@NgModule({
    declarations: [PageheaderComponent, HomeComponent, ItemlistComponent, LoadingComponent],
    exports: [
        PageheaderComponent,
        ItemlistComponent,
        MbscModule,
        ReactiveFormsModule,
        RouterModule,
        FormsModule,
        ImageHandlerModule,
        PageheaderComponent,
        HomeComponent,
        ItemlistComponent,
        LoadingComponent
    ],
    imports: [
        CommonModule,
        ImageHandlerModule,
        FormsModule,
        MbscModule,
        ReactiveFormsModule,
        RouterModule
    ]
})
export class SharedModule { }
