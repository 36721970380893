import {Injectable} from "@angular/core";
import {Router} from "@angular/router";

@Injectable({
    providedIn: 'root'
})
export class UserActionsService {
    constructor(private _router: Router) {}
    logout() {
        this.removeUserDetails();
        this._router.navigate(['/login']);
    }
    removeUserDetails() {
        localStorage.removeItem('a_u_t');
        localStorage.removeItem('a_i_t');
        localStorage.removeItem('expiration');
        localStorage.removeItem('logo');
    }
}