import {NgModule, Optional, SkipSelf} from "@angular/core";
import {CommonModule} from "@angular/common";
import {HTTP_INTERCEPTORS, HttpClientModule} from "@angular/common/http";
import {RequestInterceptorService} from "@core/services/http.interceptor.service";
import { ConfirmDialogBoxComponent } from './components/confirm-dialog-box/confirm-dialog-box.component';

@NgModule({
    declarations: [ConfirmDialogBoxComponent],
    imports: [
        CommonModule,
        HttpClientModule
    ],
    exports: [
        ConfirmDialogBoxComponent
    ],
    providers: [{
        provide: HTTP_INTERCEPTORS,
        useClass: RequestInterceptorService,
        multi: true
    }]
})
export class CoreModule {
    constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
        if (parentModule) {
            throw new Error(
                'CoreModule is already loaded. Import it in the AppModule only'
            );
        }
    }
}