import {Component} from '@angular/core';
import {WindowService} from '@core/services/window.service';

@Component({
  selector: 'app-offer',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'adsquickinfo';

  constructor(private win: WindowService) {
    this.win.onResize()
      .subscribe(
        res => res
      );
  }

}
