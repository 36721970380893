import {Injectable} from "@angular/core";
import {UserDetail} from "../../shared/interface/userDetail.interface";

@Injectable({
    providedIn: 'root'
})
export class TokenService {
    constructor() {}
    static checkAuthenticatedState(): boolean {
        const currentTime = Math.floor(Date.now() / 1000);
        return (localStorage.getItem('a_u_t')
            && localStorage.getItem('a_i_d')
            && (parseInt(localStorage.getItem('expiration'), 10) - (60*60*24)) > currentTime);
    }
    static setUserDetail(userDetails: UserDetail) {
        localStorage.setItem('a_u_t', userDetails.access_token);
        localStorage.setItem('a_i_d', userDetails.userId);
        localStorage.setItem('expiration', userDetails.expiration.toString());
        localStorage.setItem('logo', userDetails.userLogo);
    }
    static getToken(): string {
        return localStorage.getItem('a_u_t');
    }
    static getUserId(): string {
        return localStorage.getItem('a_i_d');
    }
    static getUserLogo(): string {
        return localStorage.getItem('logo');
    }
}