import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import {AppEventsService} from "@core/services/appevents.service";
import {NotificationsService} from "@core/services";
@Component({
  selector: 'image-slider',
  templateUrl: './imageslider.component.html',
  styleUrls: ['./imageslider.component.scss'],
})
export class ImagesliderComponent implements OnInit, OnDestroy {
  @Input() imageUrl: Blob;
  @Input() hasImages: boolean;
  @Input() singleCropper: boolean = true;
  @Input() disableUpload: boolean = false;
  @Input() editMode: boolean =  false;
  @Input() imgError: boolean;
  @Output() image: EventEmitter<Blob> = new EventEmitter<Blob>();
  @Output() processing: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() saveOnAllChannel: EventEmitter<boolean> = new EventEmitter<boolean>();
  @ViewChild('fileinput', {static: false}) fileInput: ElementRef;
  imageChangedEvent: any = '';
  croppedImage: Blob;
  cropperStatus: string = 'invisible';
  showCropper: boolean = false;
  isGif: boolean = false;
  aspectRatio: number;
  fileFormat: string;
  imageUploaded: boolean = false;
  imgLoaded: boolean = false;
  imageOrientation;
  private _subscriptionState: boolean = true;

  constructor(private _appEvents: AppEventsService) {
  }
  ngOnInit() {
    this._initComp();
  }

  ngOnDestroy(): void {
    this._subscriptionState = false;
  }

  fileChangeEvent(event: any): void {
    this._appEvents.showLoader(true);
    const file = event.target.files[0];
    console.log('event',event);
    if (file.type === 'image/jpg' || file.type === 'image/png' || file.type === 'image/jpeg') {
      this.isGif = false;
      this.processing.emit(true);
      document.body.classList.add('cropper-open');
      this.imageChangedEvent = file;
      this.showCropper = true;
    }
    if (file.type === 'video/mp4' || file.type === 'video/webm' || file.type === 'video/ogg') {
      this._appEvents.showLoader(false);
      NotificationsService.notify('Vælg et billede', 'error', 'top');
    }


  }
  imageCropped(event) {
    this.fileFormat = event.split(';')[0].split('/')[1];
    this.croppedImage = event;
  }

  imageLoaded() {
    this.imgLoaded = true;
  }

  cropperLoaded() {
    this.cropperStatus = 'visible';
  }

  closeCropper() {
    this._resetCropper(false);
  }
  uploadImage() {
    this._onUploadSuccess(this.croppedImage);
  }
  private _initComp() {
    this.aspectRatio = 1;
    this.croppedImage = null;
  }

  private _onUploadSuccess(url: Blob) {
    this.showCropper = false;
    this.imageUrl = url;
    this.imageUploaded = true;
    this._resetCropper(true);
  }

  private _resetCropper(state: boolean) {
    document.body.classList.remove('cropper-open');
    this.fileInput.nativeElement.value = null;
    this.cropperStatus = 'invisible';
    this.showCropper = false;
    this.fileFormat = '';
    this.processing.emit(false);
    if (state) {
      this.image.emit(this.imageUrl);
    }
  }
}
