import {Injectable} from "@angular/core";
import {
    HttpErrorResponse,
    HttpEvent,
    HttpHandler,
    HttpHeaders,
    HttpInterceptor,
    HttpRequest, HttpResponse
} from "@angular/common/http";
import {Observable, throwError} from "rxjs";
import {catchError, map} from "rxjs/operators";
import {environment} from "../../../environments/environment";
import {TokenService} from "./token.service";
import {AppEventsService} from "./appevents.service";
import {UserActionsService} from "./userActions.service";

@Injectable({
    providedIn: 'root'
})
export class RequestInterceptorService implements  HttpInterceptor {
    constructor(private _appEvents: AppEventsService,
                private _userService: UserActionsService) {}
    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(this._setRequestHeaders(req))
            .pipe(
                map((event: HttpEvent<any>) => {
                    if (event instanceof HttpResponse) {
                        this._appEvents.showLoader(false);
                    }
                    return event;
        }),
                catchError( (HttpError: HttpErrorResponse) => {
                    const isTokenInvalid = 'invalid token codes';
                    if (HttpError.status === 401 && isTokenInvalid) {
                        //logout user
                        this._userService.removeUserDetails();
                        this._appEvents.showLoader(false);
                        return throwError(HttpError.error);
                    }
                    if (!environment.production) {
                        console.log(HttpError);
                    }
                    this._appEvents.showLoader(false);
                    return  throwError(HttpError.error);
                })
            );
    }
    private _setRequestHeaders(request: HttpRequest<any>) {
        const headerObject = {};
        if (request.method === 'POST' && !(request.body instanceof FormData)) {
            headerObject['Content-type'] = 'application/json';
        }
        if (request.method === 'GET') {
            headerObject['Content-type'] = 'application/json';
        }
        // add token
        if (request.url.indexOf(environment.apiurl) !== -1) {
            if  (TokenService.checkAuthenticatedState()) {
                headerObject['Authorization'] = `Bearer ${TokenService.getToken()}`;
            }
            this._appEvents.showLoader(true);
        }
        ////
        const  headers = new HttpHeaders(headerObject);
        return request.clone({headers});
    }
}