import {Injectable} from "@angular/core";
import {Subject} from "rxjs";

@Injectable({
    providedIn: 'root'
})
export class AppEventsService {

    // show hide loader
    private _showLoader = new Subject<boolean>();
    $showLoader = this._showLoader.asObservable();

    // show loader
    showLoader(state: boolean) {
        this._showLoader.next(state);
    }
}