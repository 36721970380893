import {
  AfterViewInit,
  Component,
  EventEmitter,
  HostListener,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges
} from '@angular/core';
import Cropper from 'cropperjs';
import {AppEventsService} from "@core/services/appevents.service";

declare let loadImage: any;

@Component({
  selector: 'sm-image-cropper',
  templateUrl: './sm-image-cropper.component.html',
  styleUrls: ['./sm-image-cropper.component.scss']
})
export class SmImageCropperComponent implements OnInit, OnChanges, AfterViewInit {
  @Input() imageDestination;
  @Input() aspRatio;
  @Input() cropMinWidth;
  @Input() resizeToWidth;
  @Input() orientation;
  @Output() imageLoaded: EventEmitter<null> = new EventEmitter();
  @Output() imageCropped: EventEmitter<string> = new EventEmitter<string>();
  private  _cropper: Cropper;
  public context: CanvasRenderingContext2D;
  constructor(private _appEvents: AppEventsService ) {
    this.imageDestination = '';
  }
  imageElement: HTMLImageElement;
  isProcessing: boolean = false;

  ngOnInit() {
  }
  ngOnChanges(changes: SimpleChanges): void {
    if (changes.imageDestination.currentValue) {
      this.imageDestination = changes.imageDestination.currentValue;
      this.orientation = changes.orientation.currentValue;
      this._loadCropper();
    }
  }
  private _loadCropper() {
    this.imageLoaded.emit();
    this._setImageData();
  }
  private _setCropper(image: HTMLImageElement) {
    this._cropper = new Cropper(image, {
      responsive: false,
      zoomable: false,
      scalable: false,
      movable: false,
      rotatable: false,
      background: false,
      viewMode: 2,
      aspectRatio: 356 / 475,
      ready: () => {
        this._appEvents.showLoader(false);
        this.cropLogic();
      },
      cropend: () => {
        this.cropLogic();
      }
    });
  }
  cropLogic() {
    this._appEvents.showLoader(false);
    const canvas = this._cropper.getCroppedCanvas({width: 356, height: 475});
    const toSendImgBase = canvas.toDataURL('image/png');
    this.imageCropped.emit(toSendImgBase);
  }
  private _setImageData() {
    this._appEvents.showLoader(true);
    this.isProcessing = true;
    const options = {
      canvas: true,
      orientation: null,
      maxWidth: 1200,
    };
    loadImage.parseMetaData(this.imageDestination, data => {
      // Get the correct orientation setting from the EXIF Data
      if (data.exif) {
        const iOS = !!navigator.platform && /iPad|iPhone|iPod/.test(navigator.platform);

        options.orientation = iOS ? data.exif.get('Orientation') : 1;
      }
      loadImage(this.imageDestination, canvas => {
        const imageContainer = document.getElementById('image-container');
        const imgUrl = canvas.toDataURL();
        this.imageElement = new Image();
        this.imageElement.src = imgUrl;
        imageContainer.appendChild(this.imageElement);

        this._setCropper(this.imageElement);
        this.isProcessing = false;
      }, options);
    });

  }
  private _removeExistingCropper() {
    if (this.imageElement) {
      const existingCropperContainer = document.getElementsByClassName('cropper-container')[0];
      ;         this.imageElement.remove();
      existingCropperContainer.remove();
    }
  }
  private _reloadCropper() {
    if (!this.isProcessing) {
      this._removeExistingCropper();
      this._setImageData();
    }
  }
  ngAfterViewInit(): void {
  }
  @HostListener('window:resize', ['$event'])
  onResize() {
    // changing image side according to the screen
    this._reloadCropper();
  }
}
