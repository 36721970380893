import { Component, OnInit } from '@angular/core';
import {NotificationsService} from "../../services";
import {takeWhile} from "rxjs/operators";
import {Confirmation} from "../../../shared/interface/confirmation.interface";

@Component({
  selector: 'offer-confirm-dialog-box',
  templateUrl: './confirm-dialog-box.component.html',
  styleUrls: ['./confirm-dialog-box.component.scss']
})
export class ConfirmDialogBoxComponent implements OnInit {
  confirmation: Confirmation;
  constructor(private _confirmationService: NotificationsService) { }

  ngOnInit() {
    this._confirmationService.getMessage()
        .subscribe(res => {
          this.confirmation = res;
        })
  }

}
