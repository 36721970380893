import {Injectable} from "@angular/core";
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot} from "@angular/router";
import {Observable} from "rxjs";
import {TokenService} from "../services/token.service";

@Injectable({
    providedIn: 'root'
})
export class UserGuard implements CanActivate {
    constructor(private _router: Router
    ) {
    }

    canActivate(
        next: ActivatedRouteSnapshot,
        state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
        if (TokenService.getUserId() !== '') {
            // route to home
            this._router.navigate(['/home']).then();
            return true;
        }
        return false;
    }
}